<template>
  <div class="space-y-4">
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <asom-card title="Inventory Items">
      <template #header>
        <div class="grid sm:grid-cols-1 md:grid-cols-2">
          <div class="flex flex-wrap content-center">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Inventory Items
            </h3>
          </div>
          <div class="flex flex-row-reverse">
            <asom-export-to-excel
              fileName="Inventory Items"
              :fields="columns.fields"
              :labels="columns.labels"
              :data="columns.data"
            />
          </div>
        </div>
      </template>
      <div class="grid sm:grid-cols-1 md:grid-cols-2 md:gap-x-6 gap-4">
        <asom-form-field
          label="Line"
          :state="inputStates('filters.line')"
          required
        >
          <asom-input-select
            :disabled="!canSelectLine"
            v-model="filters.line"
            :options="lineOptions"
            :state="inputStates('filters.line')"
          />
        </asom-form-field>
        <asom-form-field
          label="Station"
          :state="inputStates('filters.station')"
          required
        >
          <asom-input-select
            v-model="filters.station"
            :options="stationOptions"
            :state="inputStates('filters.station')"
          />
        </asom-form-field>
        <asom-form-field
          label="Inventory Group"
          :state="inputStates('filters.inventoryGroup')"
          required
        >
          <asom-input-select
            v-model="filters.inventoryGroup"
            :options="inventoryGroups"
            :state="inputStates('filters.inventoryGroup')"
          />
        </asom-form-field>
      </div>
      <template #footer>
        <!-- <asom-button
          text="Reset Filters"
          variant="secondary"
          @click="resetFilters"
        /> -->
        <asom-button text="Apply" @click="getPageData" />
      </template>
    </asom-card>
    <div class="pt-8">
      <asom-client-table
        v-if="!isLoading"
        :columns="[
          'inventoryTypeName',
          'itemDescription',
          'amount',
          'primaryLocationName',
          'secondaryLocationName',
          'expiryDate',
        ]"
        :sortableColumns="[
          'inventoryTypeName',
          'itemDescription',
          'amount',
          'primaryLocationName',
          'secondaryLocationName',
          'expiryDate',
        ]"
        :data="itemList"
        :searchableDateColumns="['expiryDate']"
      >
        <template v-slot:header_inventoryTypeName>Item Type</template>
        <template v-slot:header_amount>Quantity</template>
        <template v-slot:header_primaryLocationName>Primary Location</template>
        <template v-slot:primaryLocationName="scopedSlots">
          <span>{{ scopedSlots.data ? scopedSlots.data : "-" }}</span>
        </template>
        <template v-slot:header_secondaryLocationName
          >Secondary Location</template
        >
        <template v-slot:secondaryLocationName="scopedSlots">
          <span>{{ scopedSlots.data ? scopedSlots.data : "-" }}</span>
        </template>
        <template v-slot:expiryDate="scopedSlots">
          <span>{{
            scopedSlots.data ? displayUtcDate(scopedSlots.data) : "-"
          }}</span>
        </template>
      </asom-client-table>
      <div v-else class="text-center">
        <asom-icon icon="spinner" class="animate-spin" />
      </div>
    </div>
  </div>
</template>

<script>
import get from "lodash.get";
import { useVuelidate } from "@vuelidate/core";
import inputStates from "@/mixins/inputStates";
import { required } from "@vuelidate/validators";
import { mapGetters } from "vuex";
import { displayUtcDate } from "@/helpers/dateTimeHelpers";
import { getInventoryMainPages } from "../../../services/inventory.service";
import { InventoryGroups } from "../../../constants/APIEnums/inventoryEnums";

export default {
  setup: () => ({ v$: useVuelidate() }),
  mixins: [inputStates],
  name: "ViewItemsPage",
  data() {
    const inventoryGroup = {
      label: InventoryGroups.GENERAL.NAME,
      value: InventoryGroups.GENERAL.VALUE,
    };
    return {
      isLoading: false,
      error: null,
      filters: {
        line: this.$store.getters["auth/userLineOption"],
        station: null,
        inventoryGroup: inventoryGroup,
      },
      itemList: [],
    };
  },
  validations() {
    return {
      filters: {
        line: { required },
        station: { required },
      },
    };
  },
  computed: {
    ...mapGetters({
      userLineId: "auth/userLineId",
      userStationId: "auth/userStationId",
      lineOptions: "smrtResource/lineOptions",
      canSelectLine: "auth/canSelectLine",
    }),
    stationOptions() {
      return this.$store.getters["smrtResource/stationOptionsByLineId"](
        get(this.filters.line, "value")
      );
    },
    inventoryGroups() {
      return [
        {
          label: InventoryGroups.GENERAL.NAME,
          value: InventoryGroups.GENERAL.VALUE,
        },
        {
          label: InventoryGroups.SMART_CARD.NAME,
          value: InventoryGroups.SMART_CARD.VALUE,
        },
        {
          label: InventoryGroups.SERIALISED_TAG.NAME,
          value: InventoryGroups.SERIALISED_TAG.VALUE,
        },
        {
          label: InventoryGroups.FIRST_AID.NAME,
          value: InventoryGroups.FIRST_AID.VALUE,
        },
      ];
    },
    columns() {
      const fields = [
        "inventoryTypeName",
        "itemDescription",
        "amount",
        "primaryLocationName",
        "secondaryLocationName",
        "expiryDate",
      ];
      const labels = [
        "Item Type",
        "Item Description",
        "Quantity",
        "Primary LocationName",
        "Secondary LocationName",
        "Expiry Date",
      ];
      const data = [];
      this.itemList.forEach((element, i) => {
        data[i] = fields.reduce((accm, field) => {
          let d = element[field];
          if (field == "expiryDate") {
            d = displayUtcDate(d);
          }
          accm[field] = d;
          return accm;
        }, {});
      });
      return {
        fields,
        labels,
        data,
      };
    },
  },
  watch: {
    "filters.line": function(newValue) {
      if (newValue) {
        this.filters.station = null;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.filters.station = this.$store.getters["smrtResource/stationOptionsByLineId"](
        this.userLineId, this.userStationId
      )
      this.getPageData();
    })
  },
  methods: {
    get,
    displayUtcDate,
    resetFilters() {
      this.filters.inventoryGroup = null;
    },
    async getPageData() {
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.isLoading = true;
        this.error = null;
        const resp = await getInventoryMainPages({
          stationId: get(this.filters.station, "value"),
          lineId: get(this.filters.line, "value"),
          inventoryGroup: get(this.filters.inventoryGroup, "value"),
        });
        if (resp.success) {
          this.itemList = get(resp.payload.data, "inventories", []);
          this.activityList = get(resp.payload.data, "transactions", []);
          this.isLoading = false;
          this.error = null;
        } else {
          this.error = resp.payload;
          this.$scrollTop();
          return;
        }
      } else {
        this.error = "Please complete all required fields";
        this.$scrollTop();
      }
    },
  },
};
</script>
